import Vue from "vue";

export function isValidJwt (){
  let token = localStorage.getItem('fsg-token')
  if (!token){
    return false
  }
  let payload = JSON.parse(atob(token.split('.')[1]))
  const exp = new Date(payload.exp * 1000)
  const now = new Date()
  return now < exp
}

export function get_role (){
  let token = localStorage.getItem('fsg-token')
  let payload = JSON.parse(atob(token.split('.')[1]))
  return payload.role
}

export const EventBus = new Vue()

import Vue from "vue";
import Vuex from "vuex";
Vue.use(Vuex)
import authentication from "@/store/modules/authentication";

export default new Vuex.Store({
  modules: {
    authentication
  },
})

<template>
  <div class="wrapper">
    <notifications></notifications>
    <side-bar>
      <template slot="main">
        <vs-sidebar-item id="home" to="/" v-if="['admin'].includes(role)">
          <template #icon>
            <i class='ni ni-chart-bar-32 text-primary'></i>
          </template>
        Dashboard
        </vs-sidebar-item>
        <vs-sidebar-item id="home" to="/topdesigner" v-if="['admin'].includes(role)">
          <template #icon>
            <i class='bx bxs-arrow-to-top text-danger'></i>
          </template>
          Top Designer
        </vs-sidebar-item>
        <!-- <vs-sidebar-item id="ads" to="/ads" v-if="['admin'].includes(role)">
          <template #icon>
            <i class='bx bx-injection text-danger'></i>
          </template>
          Ads
        </vs-sidebar-item> -->
        <vs-sidebar-item id="toptrend" to="/inventories" v-if="['admin'].includes(role)">
          <template #icon>
            <i class='bx bxs-right-top-arrow-circle'></i>
          </template>
          Inventories
        </vs-sidebar-item>
        <vs-sidebar-item id="toptrend" to="/topimage" v-if="['admin'].includes(role)">
          <template #icon>
            <i class='bx bxs-right-top-arrow-circle'></i>
          </template>
          Uptrend
        </vs-sidebar-item>
        <vs-sidebar-item id="ranking" to="/ranking" v-if="['admin'].includes(role)">
          <template #icon>
            <i class='bx bx-trending-up'></i>
          </template>
          Ranking
        </vs-sidebar-item>
        <vs-sidebar-item id="place" to="/place" v-if="['admin'].includes(role)">
          <template #icon>
            <i class='ni ni-money-coins text-warning'></i>
          </template>
          Place
        </vs-sidebar-item>
        <vs-sidebar-item id="history" to="/history" v-if="['admin'].includes(role)">
          <template #icon>
            <i class='bx bx-history text-yellow'></i>
          </template>
          History
        </vs-sidebar-item>
        <vs-sidebar-item id="seller" to="/seller" v-if="['admin'].includes(role)">
          <template #icon>
            <i class='bx bxs-user-account' ></i>
          </template>
          Manage
        </vs-sidebar-item>
        <!-- <vs-sidebar-group>
          <template #header>
            <vs-sidebar-item arrow v-if="['admin', 'idea', 'mod'].includes(role)">
              <template #icon>
                <i class='bx bx-bulb text-danger' ></i>
              </template>
              New
            </vs-sidebar-item>
          </template>
          <vs-sidebar-item id="fsg-nick" to="/fsg-nicks" v-if="['admin', 'mod', 'idea'].includes(role)">
            <template #icon>
              <i class='ni ni-books'></i>
            </template>
            Nicks
          </vs-sidebar-item>
          <vs-sidebar-item id="fsg-designs" to="/fsg-designs" v-if="['admin', 'idea', 'mod', 'designer'].includes(role)">
            <template #icon>
              <i class='ni ni-books'></i>
            </template>
            Designs
          </vs-sidebar-item>
          <vs-sidebar-item id="nick" to="/fsg-contents" v-if="['admin', 'crawler', 'mod'].includes(role)">
            <template #icon>
              <i class='bx bxs-book-content'></i>
            </template>
            Contents
          </vs-sidebar-item>
          <vs-sidebar-item id="nick" to="/fsg-show" v-if="['admin', 'crawler', 'mod'].includes(role)">
            <template #icon>
            </template>
            Show
          </vs-sidebar-item>

        </vs-sidebar-group> -->
        <!-- <vs-sidebar-group v-if="['admin'].includes(role)">
          <template #header>
            <vs-sidebar-item arrow>
              <template #icon>
                <i class='bx bx-box' ></i>
              </template>
              Generator
            </vs-sidebar-item>
          </template>
          <vs-sidebar-item id="shirt" to="/shirt">
            <template #icon>
              <i class='bx bxs-t-shirt text-yellow'></i>
            </template>
            Shirt
          </vs-sidebar-item>
          <vs-sidebar-item id="canvas" to="/canvas">
            <template #icon>
              <i class='bx bx-square-rounded text-orange'></i>
            </template>
            Canvas
          </vs-sidebar-item>
          <vs-sidebar-item id="poster" to="/poster">
            <template #icon>
              <i class='bx bx-move-vertical' ></i>
            </template>
            Poster
          </vs-sidebar-item>
        </vs-sidebar-group> -->
        <vs-sidebar-group>
          <template #header>
            <vs-sidebar-item arrow>
              <template #icon>
                <i class='ni ni-bullet-list-67 text-green'></i>
              </template>
              Designs
            </vs-sidebar-item>
          </template>
          <vs-sidebar-item id="redesign" to="/redesign" v-if="['admin', 'mod', 'designer'].includes(role)">
            <template #icon>
              <i class='bx bx-images text-danger' ></i>
            </template>
            Design
          </vs-sidebar-item>
          <vs-sidebar-item id="finished" to="/finished" v-if="['admin', 'mod', 'designer'].includes(role)">
            <template #icon>
              <i class='bx bx-images text-danger' ></i>
            </template>
            Finished
          </vs-sidebar-item>
          <vs-sidebar-item id="tiktoklisting" to="/tiktoklisting" v-if="['admin', 'mod', 'free'].includes(role)">
            <template #icon>
              <i class='bx bx-tiktok text-danger' ></i>
            </template>
            Tiktok Listing
          </vs-sidebar-item>
          <vs-sidebar-item id="etsylisting" to="/etsylisting" v-if="['admin', 'mod'].includes(role)">
            <template #icon>
              <i class='bx bx-images text-danger' ></i>
            </template>
            Etsy Listing
          </vs-sidebar-item>
        </vs-sidebar-group>
        <vs-sidebar-group v-if="['admin'].includes(role)">
          <template #header>
            <vs-sidebar-item arrow>
              <template #icon>
                <i class='bx bx-text text-black' ></i>
              </template>
              Contents
            </vs-sidebar-item>
          </template>
            <vs-sidebar-item id="recontent" to="/recontent" v-if="['admin', 'mod', 'crawler'].includes(role)">
              <template #icon>
                <i class='bx bx-text text-black' ></i>
              </template>
              Poster
            </vs-sidebar-item>
            <vs-sidebar-item id="mug" to="/merch-mug" v-if="['admin', 'mod', 'crawler'].includes(role)">
              <template #icon>
                <i class='bx bx-text text-black' ></i>
              </template>
              Merch
            </vs-sidebar-item>
            <vs-sidebar-item id="blanket" to="/blanket" v-if="['admin', 'mod', 'crawler'].includes(role)">
              <template #icon>
                <i class='bx bx-text text-black' ></i>
              </template>
              Blanket
            </vs-sidebar-item>
            <vs-sidebar-item id="mug" to="/mug" v-if="['admin', 'mod', 'crawler'].includes(role)">
              <template #icon>
                <i class='bx bx-text text-black' ></i>
              </template>
              Mug
            </vs-sidebar-item>
            <vs-sidebar-item id="phone-case" to="/phone-case" v-if="['admin', 'mod', 'crawler'].includes(role)">
              <template #icon>
                <i class='bx bx-text text-black' ></i>
              </template>
              Phone case
            </vs-sidebar-item>
            <vs-sidebar-item id="Pillow" to="/pillow" v-if="['admin', 'mod', 'crawler'].includes(role)">
              <template #icon>
                <i class='bx bx-text text-black' ></i>
              </template>
              Pillow
            </vs-sidebar-item>
            <vs-sidebar-item id="Tote" to="/tote" v-if="['admin', 'mod', 'crawler'].includes(role)">
              <template #icon>
                <i class='bx bx-text text-black' ></i>
              </template>
              Tote
            </vs-sidebar-item>
            <vs-sidebar-item id="gallery" to="/gallery" v-if="['admin', 'mod'].includes(role)">
              <template #icon>
                <i class='bx bx-category'></i>
              </template>
              Gallery
            </vs-sidebar-item>
            <vs-sidebar-item id="spam" to="/spam" v-if="['admin', 'mod', 'crawler'].includes(role)">
              <template #icon>
                <i class='bx bxl-redbubble text-danger'></i>
              </template>
              Spam
            </vs-sidebar-item>
            <vs-sidebar-item id="spam" to="/keep_all" v-if="['admin', 'mod', 'crawler'].includes(role)">
              <template #icon>
                <i class='bx bxl-redbubble text-danger'></i>
              </template>
              Keep all
            </vs-sidebar-item>
            <vs-sidebar-item id="spam" to="/hijack" v-if="['admin', 'mod', 'crawler', 'designer'].includes(role)">
              <template #icon>
                <i class='bx bxl-amazon text-warning'></i>
              </template>
              Hijack
            </vs-sidebar-item>
        </vs-sidebar-group>
        <vs-sidebar-group v-if="['admin', 'mod'].includes(role)">
          <template #header>
            <vs-sidebar-item arrow>
              <template #icon>
                <i class='ni ni-bullet-list-67 text-green'></i>
              </template>
              Products
            </vs-sidebar-item>
          </template>
          <!-- <vs-sidebar-item id="helium" to="/helium" v-if="['admin', 'mod', 'listing'].includes(role)">
            <template #icon>
              <i class='bx bx-key text-danger'></i>
            </template>
            Helium
          </vs-sidebar-item> -->
          <vs-sidebar-item id="mockups" to="/mockups" v-if="['admin', 'mod', 'listing'].includes(role)">
            <template #icon>
              <i class='bx bx-key text-danger'></i>
            </template>
            Mockups
          </vs-sidebar-item>
          <vs-sidebar-item id="rbstore" to="/rbstore" v-if="['admin', 'mod', 'crawler'].includes(role)">
            <template #icon>
              <i class='bx bxl-redbubble bx-spin bx-rotate-180 text-red' ></i>
            </template>
            Store 0
          </vs-sidebar-item>
          <vs-sidebar-item id="esstore" to="/esstore" v-if="['admin'].includes(role)">
            <template #icon>
              <i class='bx bxl-etsy bx-tada bx-rotate-270' ></i>
            </template>
            Etsy
          </vs-sidebar-item>
          <vs-sidebar-item id="amzstore" to="/amstore" v-if="['admin'].includes(role)">
            <template #icon>
              <i class='bx bxl-amazon bx-burst bx-flip-horizontal text-blue' ></i>
            </template>
            Amazon
          </vs-sidebar-item>
          <vs-sidebar-item id="socstore" to="/store3" v-if="['admin'].includes(role)">
            <template #icon>
              <i class='ni ni-bag-17 text-primary' ></i>
            </template>
            Redbubble
          </vs-sidebar-item>
          <vs-sidebar-item id="merch" to="/merch" v-if="['admin', 'mod'].includes(role)">
            <template #icon>
              <i class='bx bxl-amazon bx-burst bx-flip-horizontal text-blue' ></i>
            </template>
            Merch
          </vs-sidebar-item>
          <vs-sidebar-item id="keywords" to="/keywords" v-if="['admin'].includes(role)">
            <template #icon>
              <i class='bx bx-edit-alt' ></i>
            </template>
            Keywords
          </vs-sidebar-item>

        </vs-sidebar-group>
        <vs-sidebar-item id="work" to="/work" v-if="['admin'].includes(role)">
          <template #icon>
            <i class='bx bxs-hard-hat text-red'></i>
          </template>
          Work
        </vs-sidebar-item>
        <vs-sidebar-item id="Uploads" to="/uploads" v-if="['admin', 'mod'].includes(role)">
          <template #icon>
            <i class='ni ni-image text-black'></i>
          </template>
          Uploads
        </vs-sidebar-item>
        <!-- <vs-sidebar-item id="Uploads" to="/fsgstore" v-if="['admin'].includes(role)">
          <template #icon>
            <i class='bx bx-store text-green'></i>
          </template>
          FSG store
        </vs-sidebar-item> -->
        <!-- <vs-sidebar-group v-if="['admin', 'mod'].includes(role)">
          <template #header>
            <vs-sidebar-item arrow>
              <template #icon>
                <i class='ni ni-cart text-gray'></i>
              </template>
              Orders
            </vs-sidebar-item>
          </template>
          <vs-sidebar-item id="designs" to="/upload_designs">
            <template #icon>
              <i class='ni ni-cloud-upload-96 text-blue'></i>
            </template>
            Upload designs
          </vs-sidebar-item>
        </vs-sidebar-group> -->
      </template>

    </side-bar>
    <div class="main-content ml-4">
      <dashboard-navbar v-if="!$route.meta.hideNavbar" :type="$route.meta.navbarType" class="ml-4"></dashboard-navbar>

      <div @click="$sidebar.displaySidebar(false)">
        <fade-transition :duration="200" origin="center top" mode="out-in">
          <!-- your content here -->
          <router-view></router-view>
        </fade-transition>
      </div>
      <content-footer v-if="!$route.meta.hideFooter"></content-footer>
    </div>
  </div>
</template>
<script>
  /* eslint-disable no-new */
  import PerfectScrollbar from 'perfect-scrollbar';
  import 'perfect-scrollbar/css/perfect-scrollbar.css';

  function hasElement(className) {
    return document.getElementsByClassName(className).length > 0;
  }

  function initScrollbar(className) {
    if (hasElement(className)) {
      new PerfectScrollbar(`.${className}`);
    } else {
      // try to init it later in case this component is loaded async
      setTimeout(() => {
        initScrollbar(className);
      }, 100);
    }
  }

  import DashboardNavbar from './DashboardNavbar.vue';
  import ContentFooter from './ContentFooter.vue';
  import DashboardContent from './Content.vue';
  import { FadeTransition } from 'vue2-transitions';

  export default {
    components: {
      DashboardNavbar,
      ContentFooter,
      DashboardContent,
      FadeTransition
    },
    data: () => ({
      'role': localStorage.getItem('role'),
      'team': localStorage.getItem('team'),
    }),
    methods: {
      initScrollbar() {
        let isWindows = navigator.platform.startsWith('Win');
        if (isWindows) {
          initScrollbar('sidenav');
        }
      }
    },
    mounted() {
      this.initScrollbar()
    }
  };
</script>
<style lang="scss">
</style>

<template>
  <nav class="fixed-left" id="sidenav-main">
    <div class="container-fluid">
      <vs-sidebar
        hover-expand
        reduce
        v-model="active"
        open
      >
        <template #logo>
          <img src="../../assets/logo.png" alt="">
        </template>
        <slot name="main"></slot>
        <template #footer>
          <vs-row justify="space-between">
            <vs-avatar badge-color="danger" badge-position="top-right">
              <i class='bx bx-bell' ></i>

              <template #badge>
                28
              </template>
            </vs-avatar>
          </vs-row>
        </template>
      </vs-sidebar>
    </div>
  </nav>
</template>
<script>
  export default {
    name: "sidebar",
    components: {

    },
    data:() => ({
      active: 'home',
    }),
    props: {
      square: true,
    }
  }
  </script>

import auth from "@/api/auth";
import {isValidJwt, EventBus} from "@/util";

const state = () => ({
  user: {
    email: "",
    role: "",
    token: "",
    exp: ""
  },
})

const getters = {
  isAuthenticated (state) {
    return isValidJwt(state.user)
  },
  isValidRole: state => (path) => {
    let roles = {
      'mod': [],
      'admin': [],
      'crawler': ['redbubble', 'icon'],
      'listing': [],
      'designer': [],
      'anothor': []
    }
    if (!state.user.role in roles || !roles[state.user.role].contains([path])){
      return false
    }
  },
}

const actions = {
  login(context, user){
    return auth.get_token(user)
      .then(resp => resp.data.message.token)
      .then(token => {context.commit('setUserData', token)})
      .catch(error => {
        console.log(error)
        EventBus.$emit('failedAuthentication', error)
      })
  }
}

const mutations = {
  setUserData(state, token){
      let payload = JSON.parse(atob(token.split('.')[1]))
      state.user.email = payload.email
      state.user.role = payload.role
      state.user.token = token
      state.user.exp = payload.exp
      state.user.name = payload.name
      localStorage.setItem('fsg-token', token)
      localStorage.setItem('role', payload.role)
      localStorage.setItem('name', payload.name)
      localStorage.setItem('team', payload.team)
      localStorage.setItem('id', payload.id)
    }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}

<template>
  <div>
    <label class="form-control-label">
      {{ textlabel }}
    </label>
    <b-form-select v-model="selected" class="super-size form-control-label" :options="options" v-bind:class="{ 'color-priority': selected === '2', 'color-order': selected === '1' }"></b-form-select>

  </div>
</template>

<script>
export default {
  name: "BaseSelect",
  data(){
    return{
      selected: this.isSelected
    }
  },
  props: {
    isSelected: null,
    data: Object,
    id: String,
    options: {
      type: Array,
    },
    textlabel: {
      type: String,
      default: null
    },
    required: true
  },
  watch: {
    selected: function (){
      this.$emit('change', this.selected, this.data)
    }
  },
}
</script>

<style scoped>
.super-size {
  min-width: 100px;
  /* background: red; */
}
.color-priority {
  background-color: tomato;
}
.color-order {
  background-color: yellowgreen;
}

</style>

<template>
  <footer class="footer px-2">
    <b-row align-v="center" class="justify-content-lg-between">
      <b-col lg="6">
        <div class="copyright text-center text-lg-left text-muted">
          © {{year}} <a href="mailto:csdlmysql@outlook.com" class="font-weight-bold ml-1" target="_blank">Contact Us</a>
          © <a href="https://m.me/csdlmysql" class="font-weight-bold ml-1" target="_blank">Author</a>
        </div>
      </b-col>
    </b-row>
  </footer>
</template>
<script>
export default {
  data() {
    return {
      year: new Date().getFullYear()
    };
  }
};
</script>
<style></style>

<template>
  <div>
    <base-select class="form-control-label" required @change="updateSelected" :v-model="isSelected" :id=id :options="products" :textlabel="textlabel"></base-select>
  </div>
</template>

<script>
import BaseSelect from "@/components/BaseSelect";
import fsg from "@/api/base";
export default {
  name: "Product",
  props: {
    textlabel: String,
    id: String,
    isSelected: null,
  },
  methods: {
    updateSelected: function (val){
      this.selected = val
    }
  },
  data() {
    return {
      selected: this.isSelected,
      products: []
    }
  },
  components: {
    BaseSelect
  },
  watch: {
    selected: function (){
      this.$emit('change', this.selected)
    }
  },
  mounted() {
    fsg.get("/products?scope=all")
      .then(resp => {
        this.products = resp.data.message
        this.selected = this.products['value']
      })
  }
}
</script>

<style scoped>

</style>
